/*
 * @description: 
 * @Author: Jay
 * @Date: 2025-03-06 17:00:49
 * @LastEditors: Jay
 * @LastEditTime: 2025-03-07 10:56:11
 */
import type { Directive, DirectiveBinding } from "vue";

export const animate: Directive = {
    // 在绑定元素的父组件 及他自己的所有子节点都挂载完成后调用
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        // console.log(el, binding)
        // const bindingName = binding.value;
        // console.log(bindingName)

        const options = {
            root: null, // 相对于视口
            rootMargin: '0px',
            threshold: 0.5 // 当目标元素50%进入视口时触发
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // 元素进入视口
                    // console.log('元素进入视口');

                    if (el.className.indexOf(binding.value) == -1) {
                        // 下面单引号中间是一个空格
                        el.className = binding.value + ' ' + el.className
                    }

                    // 加载完成后停止观察
                    observer.unobserve(el);
                }
                // else {
                //     // 元素离开视口
                //     console.log('元素离开视口');
                // }
            });
        }, options);

        observer.observe(el);
    },
    // 绑定元素的父组件卸载后调用
    unmounted() {
        // console.log(el,binding)
    }
}

/*
https://blog.csdn.net/Xiaoxiplus/article/details/126349905
app 导入  @import "./styles/css/animate.min.css";
在页面中引用   <div v-animate="'animate__animated animate__fadeInUp'"></div>
或者是  <div v-animate="one"></div>
再style样式中写入自己定义的样式
.one{
    transform:scale(1.5);
    transition: 0.5s;
}
*/