/*
 * @description: 用户-数据
 * @Author: Jay
 * @Date: 2024-10-31 10:56:35
 * @LastEditors: Jay
 * @LastEditTime: 2025-02-25 16:33:52
 */
import { sessionStorage } from "@/utils/localstorage"

export default {
    namespaced: true,
    state: {
        // 存储token 没登录默认false
        Authorization: sessionStorage.get('Authorization') ? sessionStorage.get('Authorization') : '',
    },
    getters: {},
    mutations: {
        LOG_IN(state: { Authorization: string; }, params: string) {
            // 存储token
            state.Authorization = params;
            sessionStorage.set('Authorization', params);
            // console.log('存储token', state.Authorization)
        },
        LOGO_UT(state: any) {
            state.Authorization = '';
            sessionStorage.clear();
        },
    },
    actions: {

    },
}