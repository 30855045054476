/*
 * @description: 
 * @Author: Jay
 * @Date: 2025-03-05 09:47:43
 * @LastEditors: Jay
 * @LastEditTime: 2025-03-07 10:52:38
 */
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

const app = createApp(App)

import setupPlugins from "@/plugins";
// 注册插件
app.use(setupPlugins);

app.use(store).mount('#app')
