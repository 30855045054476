/*
 * @description: 
 * @Author: Jay
 * @Date: 2025-03-05 09:47:43
 * @LastEditors: Jay
 * @LastEditTime: 2025-03-31 15:03:04
 */
import type { App } from "vue";
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

// 导入 路由
import { topMenu } from "./modules/topMenu";

const routes: Array<RouteRecordRaw> = [
  // 登录
  // {
  //   path: "/",
  //   name: "logInView",
  //   component: () => import('../views/LogInView/LogInView.vue'),
  //   meta: {
  //     title: "登录",
  //     //是否需要登录
  //     requireAuth: false
  //   }
  // }
  {
    path: '/NotFound',
    component: () => import('../components/NotFound/NotFound.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  //整合路由
  routes: [...routes, ...topMenu],
  // 刷新时，滚动条位置还原
  scrollBehavior: () => ({ left: 0, top: 0 }),
})


/*
  前置 路由守卫
*/
/* eslint-disable */
router.beforeEach((to, from, next) => {
  /* -----通过本地缓存进行判断,获取指定key本地存储的值进行判断----- */
  if (to.meta.requireAuth) {
    // 获取指定key本地存储的值
    const token = localStorage.getItem('Authorization')
    if (token === null || token === '') {
      //登录弹窗
      // console.error("路由守卫-去登录")
      router.push('/loginView')
    } else {
      next()
    }
  } else {
    next()
  }
})
/* eslint-disable no-new */

/*
  后置 路由守卫
*/
router.afterEach((to: any, from) => {
  //更改每个页面的标题
  document.title = to.meta.title;
})

// 全局注册 router
export function setupRouter(app: App<Element>) {
  app.use(router);
}

export default router
