/*
 * @description: 本地存储 操作
 * @Author: Jay
 * @Date: 2022-06-13 11:56:01
 * @LastEditors: Jay
 * @LastEditTime: 2025-01-20 11:35:02
 */

/*
    本地存储
*/
export const storage = {
    //存储
    set(key: string, value: any) {
        window.localStorage.setItem(key, JSON.stringify(value))
    },
    //取出数据
    get(key: string) {
        const value = window.localStorage.getItem(key)
        if (value && value != "undefined" && value != "null") return JSON.parse(value)
        else return null
    },
    // 删除数据
    remove(key: string) {
        window.localStorage.removeItem(key)
    },
    // 清空
    clear() {
        window.localStorage.clear()
    }
};

/*
    会话存储
 */
export const sessionStorage = {
    //存储
    set(key: string, value: any) {
        window.sessionStorage.setItem(key, JSON.stringify(value))
    },
    //取出数据
    get(key: string) {
        const value = window.sessionStorage.getItem(key);
        if (value && value != "undefined" && value != "null") return JSON.parse(value)
        return null
    },
    // 删除数据
    remove(key: string) {
        window.sessionStorage.removeItem(key)
    },
    // 清空
    clear() {
        window.sessionStorage.clear()
    }
}