/*
 * @description: 路由菜单
 * @Author: Jay
 * @Date: 2025-01-16 14:39:09
 * @LastEditors: Jay
 * @LastEditTime: 2025-01-16 17:31:45
 */
import { sessionStorage } from "@/utils/localstorage"
export default {
    namespaced: true,
    state: {
        //顶部-菜单ID
        menuId: sessionStorage.get('topMenuId') ? sessionStorage.get('topMenuId') : 0,
    },
    getters: {},
    mutations: {
        //设置 菜单ID
        SET_MENU_ID(state: { menuId: number; }, params: number) {
            state.menuId = params;
            //临时缓存
            sessionStorage.set('topMenuId', params)
        },
    },
    actions: {

    },
}