/*
 * @description: 
 * @Author: Jay
 * @Date: 2025-02-25 13:58:09
 * @LastEditors: Jay
 * @LastEditTime: 2025-03-19 17:09:46
 */
import { RouteRecordRaw } from 'vue-router'

export const topMenu: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "layout",
        component: () => import('../../layout/layout.vue'),
        redirect: "/homeView",
        children: [
            {
                path: "/homeView",
                name: "homeView",
                component: () => import('../../views/HomeView/HomeView.vue'),
                meta: {
                    title: "首页",
                    //是否需要登录
                    requireAuth: false
                }
            },
            // {
            //     path: "/solutionView",
            //     name: "solutionView",
            //     component: () => import('../../views/SolutionView/SolutionView.vue'),
            //     meta: {
            //         title: "解决方案",
            //         //是否需要登录
            //         requireAuth: false
            //     }
            // },
            {
                path: "/ecologicalCooperation",
                name: "ecologicalCooperation",
                component: () => import('../../views/EcologicalCooperation/EcologicalCooperation.vue'),
                meta: {
                    title: "生态合作",
                    //是否需要登录
                    requireAuth: false
                }
            },
            {
                path: "/aboutUs",
                name: "aboutUs",
                component: () => import('../../views/AboutUs/AboutUs.vue'),
                meta: {
                    title: "关于我们",
                    //是否需要登录
                    requireAuth: false
                }
            },
            {
                path: "/contactUs",
                name: "contactUs",
                component: () => import('../../views/ContactUs/ContactUs.vue'),
                meta: {
                    title: "联系我们",
                    //是否需要登录
                    requireAuth: false
                }
            },
            // 产品服务-分账支付服务
            ...productSplitPay(),
            // 产品服务-海外营销服务
            ...productService(),
            // 产品服务-SUPER系列
            ...productSaas(),
            // 解决方案-二级菜单
            ...solutionMenu(),
        ]
    }
]

// 产品服务-分账支付服务
function productSplitPay() {
    return [
        {
            path: "/complianceLedger",
            name: "complianceLedger",
            component: () => import('../../views/ServiceSplitPay/ComplianceLedger/ComplianceLedger.vue'),
            meta: {
                title: "合规分账",
                //是否需要登录
                requireAuth: false
            }
        },
        {
            path: "/aggregatePayment",
            name: "aggregatePayment",
            component: () => import('../../views/ServiceSplitPay/AggregatePayment/AggregatePayment.vue'),
            meta: {
                title: "聚合支付",
                //是否需要登录
                requireAuth: false
            }
        }
    ]
}

// 产品服务-海外营销服务
function productService() {
    return [
        {
            path: "/accountTopUp",
            name: "accountTopUp",
            component: () => import('../../views/ServiceMarketing/AccountTopUp/AccountTopUp.vue'),
            meta: {
                title: "海外大媒体 开户充值",
                //是否需要登录
                requireAuth: false
            }
        },
        {
            path: "/optimizedInvestment",
            name: "optimizedInvestment",
            component: () => import('../../views/ServiceMarketing/OptimizedInvestment/OptimizedInvestment.vue'),
            meta: {
                title: "海外大媒体优化代投",
                //是否需要登录
                requireAuth: false
            }
        },
        {
            path: "/affiliateMarketing",
            name: "affiliateMarketing",
            component: () => import('../../views/ServiceMarketing/AffiliateMarketing/AffiliateMarketing.vue'),
            meta: {
                title: "Affiliate联盟营销",
                //是否需要登录
                requireAuth: false
            }
        },
        {
            path: "/publicistSolution",
            name: "publicistSolution",
            component: () => import('../../views/ServiceMarketing/PublicistSolution/PublicistSolution.vue'),
            meta: {
                title: "全球媒体公关 解决方案",
                //是否需要登录
                requireAuth: false
            }
        }
    ]
}

// 产品服务-SUPER系列
function productSaas() {
    return [{
        path: "/agentSaas",
        name: "agentSaas",
        component: () => import('../../views/ServiceSupers/AgentSaas/AgentSaas.vue'),
        meta: {
            title: "SaaS支付服务平台",
            //是否需要登录
            requireAuth: false
        }
    }, {
        path: "/mediascape",
        name: "mediascape",
        component: () => import('../../views/ServiceSupers/Mediascape/Mediascape.vue'),
        meta: {
            title: "SaaS电商供应链金融平台",
            //是否需要登录
            requireAuth: false
        }
    },]
}

// 解决方案-二级菜单
function solutionMenu() {
    return [{
        path: "/dianshanghy",
        name: "dianshanghy",
        component: () => import('../../views/SolutionSubmenu/Dianshanghy/Dianshanghy.vue'),
        meta: {
            title: "电商行业",
            //是否需要登录
            requireAuth: false
        }
    }, {
        path: "/b2bSupplyChain",
        name: "b2bSupplyChain",
        component: () => import('../../views/SolutionSubmenu/B2BSupplyChain/B2BSupplyChain.vue'),
        meta: {
            title: "B2B 供应链",
            //是否需要登录
            requireAuth: false
        }
    }, {
        path: "/industrialInternet",
        name: "industrialInternet",
        component: () => import('../../views/SolutionSubmenu/IndustrialInternet/IndustrialInternet.vue'),
        meta: {
            title: "产业互联网",
            //是否需要登录
            requireAuth: false
        }
    }]
}