/*
 * @description: 
 * @Author: Jay
 * @Date: 2025-03-06 16:58:18
 * @LastEditors: Jay
 * @LastEditTime: 2025-03-06 16:58:25
 */
import type { App } from "vue";

import { animate } from "./animate";

// 全局注册 directive
export function setupDirective(app: App<Element>) {
  // 使 v-animate 在所有组件中都可用
  app.directive("animate", animate);
}
