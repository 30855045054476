/*
 * @description: 全局-模块
 * @Author: Jay
 * @Date: 2024-10-31 10:58:42
 * @LastEditors: Jay
 * @LastEditTime: 2024-11-04 15:24:56
 */
export default {
    namespaced: true,
    state: {

    },
    getters: {},
    mutations: {

    },
    actions: {

    },
}