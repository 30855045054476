/*
 * @description: 
 * @Author: Jay
 * @Date: 2025-01-14 17:13:06
 * @LastEditors: Jay
 * @LastEditTime: 2025-01-15 10:16:07
 */
import type { App } from "vue";

import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn' // 汉化 element-plus 组件
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

// 全局注册 汉化 element-plus 组件
export function setupElIang(app: App<Element>) {
    app.use(ElementPlus, {
        locale: zhCn,
    })
}

// 注册所有图标
export function setupElIcons(app: App<Element>) {
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component);
    }
}