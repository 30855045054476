/*
 * @description: 
 * @Author: Jay
 * @Date: 2025-02-25 13:21:31
 * @LastEditors: Jay
 * @LastEditTime: 2025-02-25 16:31:40
 */
import { createStore } from 'vuex'

import user from "./modules/user"
import menus from "./modules/menus"
import rests from "./modules/rests"


// 定义状态接口
interface AppState {
  user: {
    Authorization: string,
  }
  menus: {
    menuId: string
  },
}

export default createStore<AppState>({
  // 分模块
  modules: {
    user,
    rests,
    menus
  },
  getters: {
    Authorization: (state) => state.user.Authorization,
    menuId: (state) => state.menus.menuId,
  }
})