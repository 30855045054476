/*
 * @description: 全局导入-插件
 * @Author: Jay
 * @Date: 2025-01-15 10:10:30
 * @LastEditors: Jay
 * @LastEditTime: 2025-03-06 16:59:02
 */

import type { App } from "vue";
import { setupElIang, setupElIcons } from "./element-plus";
import { setupRouter } from "@/router";
import { setupDirective } from "@/directive";

export default {
    install(app: App<Element>) {
        // 自定义指令(directive)
        setupDirective(app);
        // 路由(router)
        setupRouter(app);
        // Element-plus图标
        setupElIcons(app);
        // 汉化 element-plus
        setupElIang(app);
    },
};