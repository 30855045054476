<!--
 * @description: 
 * @Author: Jay
 * @Date: 2025-03-05 09:47:43
 * @LastEditors: Jay
 * @LastEditTime: 2025-03-06 11:34:49
-->
<template>
  <router-view />
</template>

<style lang="scss">
//自定义
@import "./styles/css/reset.css";
@import "./styles/css/common.css";
@import "./styles/css/custom.scss";
@import "./styles/css/icon.css";
@import "./styles/css/animate.min.css";

.min-max-width {
  min-width: 1270px;
  max-width: 1270px;
  margin: auto;
}

.min-w {
  min-width: 1270px;
}

// 文字 渐变色
.gradient-text {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#8fd0ff),
    color-stop(50%, #3787ff),
    to(#144ee3)
  );
  background-image: linear-gradient(90deg, #8fd0ff, #3787ff 50%, #144ee3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
